import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSC.jpg"

function Hvac() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap hvacWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap hvacOverview">
          <div className="textWrap">
            <h2>HVAC Air conditioning system</h2>
            <p className="bodyText">
              Mobile and wireless network technologies enable 24-hour monitoring
              in small and medium-sized buildings and minimize energy management
              costs. Sensors installed at the required points automatically
              measure temperature to identify people and automatically control
              air conditioning and air conditioning systems over the network.
              <br />
              <br />
              Based on the accumulated database, the ELSA solution that
              calculates the used space and access personnel to calculate the
              actual air conditioning system usage, and uses automatic control
              to create the optimal space. Classrooms and dormitories are
              automatically turned on and off automatically, and the air
              conditioning system can be automatically turned on after school.
              In addition, each building can be connected to the network for
              monitoring and centralized control.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Hvac
